import IsdCountryDropdown1 from './theme1'
import IsdCountryDropdown2 from './theme2'
import IsdCountryDropdown4 from './theme4'
import IsdCountryDropdown5 from './theme5'

export default function IsdCountryDropdown(props) {
  const { theme } = props
  switch (theme) {
    case 'theme1':
      return <IsdCountryDropdown1 {...props} />
    case 'theme2':
      return <IsdCountryDropdown2 {...props} />
    case 'theme4':
      return <IsdCountryDropdown4 {...props} />
    case 'theme5':
      return <IsdCountryDropdown5 {...props} />
    default:
      return <IsdCountryDropdown4 {...props} />
  }
}
