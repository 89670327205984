import React, { useState, useRef } from 'react';
import styles from './index.module.css';


const Theme1 = ({ selectCountryCode, dropdownName, CountryListOptions, searchText, selectedCountryInfo, onClickInputCountry,
    onChangeInputCountryCodeFunction, onKeyDownCountry, placeholder, country_drop_styles = undefined, isCountryFlagVisible = true,
    isCountryCodeVisible = true, input_country_style = undefined }) => {

    const [openList, setopenList] = useState(false)
    const [shouldShowFlag, setshouldShowFlag] = useState(selectedCountryInfo?.countryFlag ? true : false)
    const [showCountryCode, setshowCountryCode] = useState(selectedCountryInfo?.countryCode ? true : false)
    const [countryFlag, setcountryFlag] = useState('')
    const [showNoResultFound, setshowNoResultFound] = useState(false)


    const statusFilterLayerRef = useRef(null);

    function onStatusFilterClick() {
        if (!openList) {
            setopenList(true)
            // setState({ openList: true });
            document.addEventListener('click', handleClickOutsideOfonStatusFilterClick, true);
        }
        else {
            document.removeEventListener('click', handleClickOutsideOfonStatusFilterClick, true);
            setopenList(false)
            setshouldShowFlag(true)
            // setState({ openList: false, shouldShowFlag: true });
        }
    }

    function handleClickOutsideOfonStatusFilterClick(event) {

        if (statusFilterLayerRef.current && !statusFilterLayerRef.current.contains(event.target)) {
            setopenList(false)
            setshouldShowFlag(true)
            // setState({ openList: false, shouldShowFlag: true });
            document.removeEventListener('click', handleClickOutsideOfonStatusFilterClick, true);
        }
    }

    function setSelectCountryCode(ele) {
        setshowCountryCode(true)
        // setState({ showCountryCode: true });
        selectCountryCode(ele);
    }

    const countryDropdownClick = () => {
        if (document.querySelectorAll(`[name=${dropdownName !== undefined ? dropdownName : "code-input"}]`) && document.querySelectorAll(`[name=${dropdownName !== undefined ? dropdownName : "code-input"}]`)[0]) {
            document.querySelectorAll(`[name=${dropdownName !== undefined ? dropdownName : "code-input"}]`)[0].focus();
        }
        let countryFlag1 = CountryListOptions.filter(ele => {
            let val22 = searchText !== undefined ? searchText.includes('+') ? searchText.split('+')[1] : searchText : searchText;
            return (ele.isdCode.includes(val22) || ele.countryName.includes(val22));
        });
        countryFlag1.sort((a, b) => {
            let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
            let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
            return str1 - str2;
        });
        setcountryFlag(countryFlag1)
        setshowCountryCode(true)
        if (countryFlag1.length > 0) {
            setshowNoResultFound(false)
        } else {
            setshowNoResultFound(true)
        }
    }

    const inputClickHandler = () => {
        onClickInputCountry();
        setopenList(true)
        setshouldShowFlag(true)
        setshowCountryCode(searchText !== '' ? true : false)
        let countryFlag1 = CountryListOptions.filter(ele => {
            let val22 = searchText !== undefined ? searchText.includes('+') ? searchText.split('+')[1] : searchText : searchText;
            return (ele.isdCode.includes(val22) || ele.countryName.includes(val22));
        });
        countryFlag1.sort((a, b) => {
            let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
            let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
            return str1 - str2;
        });
        setcountryFlag(countryFlag1)
        setshowCountryCode(true)
        // setState({ countryFlag: countryFlag1, showCountryCode: true });
        if (countryFlag1.length > 0) {
            setshowNoResultFound(false)
            // setState({ showNoResultFound: false });
        } else {
            setshowNoResultFound(true)
            // setState({ showNoResultFound: true });
        }
        // });
    }

    const inputChangeHandler = (e) => {
        let str = e.target.value;
        let countryFlag1;
        var pat = /^[0-9]+$/;
        let val22 = (str.match(pat)) ? (parseInt(str)) : str;   //(e.target.value !== '' && e.target.value.match(pat)) ? str.split('+')[1] : str;
        if (val22 === '+') {
            countryFlag1 = CountryListOptions;
            countryFlag1.sort((a, b) => {
                // if(a.isdCode.includes)
                let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                // a.isdCode - b.isdCode
                return str1 - str2;
            });
            setcountryFlag(countryFlag1)
            setopenList(true)
            setshouldShowFlag(true)
            setshowCountryCode(false)
            // setState({ countryFlag: countryFlag1 }, () => {
            //     setState({ openList: true, shouldShowFlag: true, showCountryCode: false });
            // })
        } else
            if (typeof val22 === "number") {
                // val22
                countryFlag1 = CountryListOptions.filter(ele => {
                    return ele.isdCode.includes(val22) //|| (ele.countryName.toLowerCase()).includes((val22.toLocaleLowerCase()))) || (ele.countryName.toUpperCase()).includes((val22.toLocaleLowerCase()));
                });
                countryFlag1.sort((a, b) => {
                    // if(a.isdCode.includes)
                    let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                    let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                    // a.isdCode - b.isdCode
                    return str1 - str2;
                });
                setcountryFlag(countryFlag1)
                setopenList(true)
                setshouldShowFlag(true)
                setshowCountryCode(false)
            } else if (typeof val22 === 'string') {
                let val25 = (val22.includes('+') && (!val22.includes('-'))) ? parseInt(val22.split('+')[1]) : val22; //.includes('+') ? value.split('+')[1] : value;
                if (typeof val25 === 'number') {
                    countryFlag1 = CountryListOptions.filter(ele => {
                        return (ele.isdCode.includes(val25))
                        //|| (ele.countryName.toLowerCase()).includes((val22.toLocaleLowerCase()))) || (ele.countryName.toUpperCase()).includes((val22.toLocaleLowerCase()));
                    });
                    countryFlag1.sort((a, b) => {
                        let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                        let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                        return str1 - str2;
                    });
                    setcountryFlag(countryFlag1)
                    setopenList(true)
                    setshouldShowFlag(true)
                    setshowCountryCode(false)
                } else if (typeof val25 === 'string') {
                    countryFlag1 = CountryListOptions.filter(ele => {
                        return (ele.isdCode.includes(val25) || (ele.countryName.toLowerCase()).includes((val22.toLocaleLowerCase()))) || (ele.countryName.toUpperCase()).includes((val22.toLocaleLowerCase()));
                    });
                    countryFlag1.sort((a, b) => {
                        let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                        let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                        return str1 - str2;
                    });
                    setcountryFlag(countryFlag1)
                    setopenList(true)
                    setshouldShowFlag(true)
                    setshowCountryCode(false)
                }
                else {
                    countryFlag1 = CountryListOptions.filter(ele => {
                        return (ele.countryName.toLowerCase()).includes((val22.toLocaleLowerCase())) || (ele.countryName.toUpperCase()).includes((val22.toLocaleLowerCase()));
                    });
                    countryFlag1.sort((a, b) => {
                        let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                        let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                        return str1 - str2;
                    });
                    setcountryFlag(countryFlag1)
                    setopenList(true)
                    setshouldShowFlag(true)
                    setshowCountryCode(false)
                }
            }
        onChangeInputCountryCodeFunction(val22);
        // console.log('counryFlag ==========', countryFlag1);
        if (countryFlag1.length > 0) {
            setshowNoResultFound(false)
        } else {
            setshowNoResultFound(true)
        }
    }

    const inputKeyUpHandler = (e) => {
        const key = e.key;
        if (key === "Backspace" || key === "Delete") {
            let countryFlag1 = CountryListOptions;
            countryFlag1.sort((a, b) => {
                let str1 = a.isdCode.replace(/[^\w\s]/gi, '');
                let str2 = b.isdCode.replace(/[^\w\s]/gi, '');
                return str1 - str2;
            });
            setcountryFlag(countryFlag1)
            setshowCountryCode(false)
            if (countryFlag1.length > 0) {
                setshowNoResultFound(false)
            } else {
                setshowNoResultFound(true)
            }
            setshouldShowFlag(true)
            setshowCountryCode(false)
            onKeyDownCountry(e);
        }
        if (key === 'Tab') {
            setopenList(false)
            setshouldShowFlag(true)
        }
    }

    return (
        <div
            className={country_drop_styles || styles.country_drop}
            onClick={onStatusFilterClick}
            ref={statusFilterLayerRef}>
            <span onClick={countryDropdownClick}
                className={/*openList ? styles.vt_country_select_main_container_new + styles.open_state_dropdown :*/ styles.vt_country_select_main_container_new}
            >
                <span role="presentation" className={styles.vt_country_select_container}>
                    {isCountryFlagVisible && shouldShowFlag && (
                        <div className={styles.cnt_img}>
                            <img
                                style={{ height: "20px", width: "25px" }}
                                className={styles.vt_flag_img}
                                src={selectedCountryInfo?.countryFlag ? selectedCountryInfo.countryFlag : 'images/user-active.png'}
                                alt=" "
                                onError={(e) => { e.target.onerror = null; e.target.src = 'images/user-active.png'; }}
                            />
                        </div>)}
                    {isCountryCodeVisible && showCountryCode &&
                        <span className={styles.selected_ctry_name}>{selectedCountryInfo?.countryCode ? selectedCountryInfo.countryCode : ''}</span>
                    }
                    <input
                        // name={selectedCountryInfo !== undefined && selectedCountryInfo !== null ? selectedCountryInfo.countryCode + (selectedCountryInfo.countryId) : 'code-input'}
                        name={dropdownName !== undefined ? dropdownName : 'code-input'}
                        className={`${input_country_style || styles.vt_inspira_country_main} ${searchText !== '' ? styles.vt_country_selected + styles.countryname_null : ""}`
                            // : styles.vt_inspira_country_main + styles.vt_country_selected + styles.countryname_null
                        }
                        onClick={inputClickHandler}
                        title={((selectedCountryInfo !== null && selectedCountryInfo !== undefined) && selectedCountryInfo.countryName)} // + searchText}
                        onChange={(e) => { inputChangeHandler(e) }}
                        onKeyUp={(e) => { inputKeyUpHandler(e) }}

                        // onBlur={() => {
                        //     console.log('on blur');
                        //     if (selectedCountryInfo === undefined && selectedCountryInfo === null) {
                        //         setState({ showCountryCode: false, searchText: '' });
                        //     }
                        // }}
                        autoComplete="new-password"
                        value={searchText}
                        placeholder={placeholder}
                    />
                </span>
                {
                    openList && (shouldShowFlag) && (
                        <div className={/* countryFlag.length <= 4 ?  */styles.vt_country_select_list_container/*  : styles.vt_country_select_list_container */}
                            ref={statusFilterLayerRef}>
                            {showNoResultFound && (<div style={{ padding: "8px" }} className='no_result_found'>{'No Result Found !'}</div>)}
                            <div className={styles.country_code_list}>
                                {countryFlag.map((ele, index) => {
                                    return (
                                        <div className={styles.country_name_code} key={index} onClick={() => { setshouldShowFlag(true); setSelectCountryCode(ele) }}>
                                            <img
                                                style={{ height: "20px", width: "25px" }}
                                                src={ele.countryFlag}
                                                alt=" "
                                                onError={e => {
                                                    e.target.onerror = null;
                                                    e.target.src = 'images/user-active.png';
                                                }}
                                            />
                                            <span className={styles.ctry_name}>{ele.countryName}</span>
                                            <span className={styles.ctry_code}>{ele.isdCode}</span>
                                        </div>

                                    );
                                })}
                            </div>
                        </div>
                    )
                }
            </span>

        </div >
    )

}
export default Theme1;